import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import AnchorButton from "../components/AnchorButton/AnchorButton";
import BlockContent from "../components/BlockContent/BlockContent";
import Breadcrumbs, { Breadcrumb } from "../components/Breadcrumbs/Breadcrumbs";
import Card from "../components/Card/Card";
import { Col, Row, Wrapper } from "../components/Grid";
import MediaCarousel from "../components/MediaCarousel/MediaCarousel";
import IsDesktop from "../components/IsDesktop/IsDesktop";
import IsMobile from "../components/IsMobile/IsMobile";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import WithSpacing from "../components/Spacing/Spacing";
import Text from "../components/Text/Text";
import GetText from "../i18n/GetText";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { SanityProduct } from "../model/buy";
import {
  CommonContext,
  SanityCallToAction,
  SanityPage,
  SanityRawContent
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import styles from "./ProductPage.module.scss";

export const pageQuery = graphql`
  query ProductPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    resellerListingPage: sanityBuyPage(language: { eq: null }) {
      _id
      _type
      title
    }
    productListingPage: sanityProductListingPage(language: { eq: null }) {
      _id
      _type
      title
    }
    buyConfig: sanityBuyConfig {
      learnMore {
        title
        internalLink {
          reference {
            ...InternalLinkTarget
          }
          fragment
          query
        }
      }
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
    page: sanityProduct(_id: { eq: $_id }) {
      _id
      _type
      title
      subtitle
      description {
        _rawContent(resolveReferences: { maxDepth: 5 })
      }
      _rawContent(resolveReferences: { maxDepth: 5 })
      image {
        ...SanitySimpleImage
        ...SanityHotspotCrop
      }
      additionalImages {
        ...SanitySimpleImage
        ...SanityHotspotCrop
      }
      slug {
        current
      }
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
  }
`;

interface ProductPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityProduct;
    buyConfig: {
      learnMore: SanityCallToAction;
      _rawContent: SanityRawContent;
    };
    productListingPage: SanityPage;
    resellerListingPage: SanityPage;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

const ProductPage = ({
  pageContext,
  data: { menus, page, productListingPage, resellerListingPage, buyConfig },
  location
}: ProductPageProps) => {
  const { title, subtitle, description, _rawContent: content } = page;
  const breadcrumbs: Breadcrumb[] = [
    {
      title: productListingPage.title,
      href: urlForDocument(productListingPage)
    },
    {
      title: page.title,
      href: urlForDocument(page)
    }
  ];
  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.BUY}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      location={location}
      strings={pageContext.strings}
    >
      <Wrapper className={styles.root}>
        <Row justify="center">
          <Col lg={10} md={12} sm={12}>
            <Row justify="between" style={{ marginBottom: "10px" }}>
              <Col>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </Col>
              <Col>
                <SocialLinks title={title} />
              </Col>
            </Row>
            <Card type="main">
              <IsMobile>
                <Row>
                  <Col md={12}>
                    <Text variant="h1">{title}</Text>
                  </Col>
                </Row>
              </IsMobile>
              <Row className={styles.carouselInfoRow}>
                <Col md={6} sm={6} xs={12} className={styles.carouselCol}>
                  <MediaCarousel
                    media={[
                      ...(page.image ? [page.image] : []),
                      ...page.additionalImages
                    ]}
                  />
                </Col>
                <Col md={6} sm={6} xs={12} className={styles.info}>
                  <WithSpacing>
                    <div>
                      <IsDesktop>
                        <Text variant="h1">{title}</Text>
                      </IsDesktop>
                      <Text variant="h3">{subtitle}</Text>
                      <BlockContent content={description?._rawContent} />
                    </div>
                  </WithSpacing>
                  <div className={styles.buttons}>
                    <AnchorButton
                      to={urlForDocument(
                        buyConfig.learnMore.internalLink!.reference
                      )}
                    >
                      {buyConfig.learnMore.title}
                    </AnchorButton>
                    <AnchorButton
                      to={`${urlForDocument(
                        resellerListingPage
                      )}?product=${encodeURIComponent(page.slug.current)}`}
                      primary
                    >
                      <GetText id="where-to-buy" fallback="Where to buy" />
                    </AnchorButton>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {content && Array.isArray(content) && content.length > 0 && (
          <Row justify="center">
            <Col lg={10} md={12} sm={12}>
              <Card type="main">
                <BlockContent
                  content={[...content, ...buyConfig._rawContent]}
                  cap="2/3"
                />
              </Card>
            </Col>
          </Row>
        )}
      </Wrapper>
    </PageLayout>
  );
};
export default ProductPage;
