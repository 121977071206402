import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import flatten from "lodash.flatten";
import sortBy from "lodash.sortby";
import uniqBy from "lodash.uniqby";
import React from "react";
import BuyFilter from "../components/BuyFilter/BuyFilter";
import { Col, Row } from "../components/Grid";
import ResellerListing from "../components/ResellerListing/ResellerListing";
import WithSpacing from "../components/Spacing/Spacing";
import Text from "../components/Text/Text";
import GetText, { useString } from "../i18n/GetText";
import BuyPageLayout from "../layouts/BuyPageLayout/BuyPageLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import {
  CommonContext,
  SanityCountry,
  SanityEcosystemMember,
  SanityPage,
  SanityRawContent
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import { useSearchParam } from "../utils/useSearchParam";
import styles from "./WhereToBuyPage.module.scss";
import { SanityProduct } from "../model/buy";
import BlockContent from "../components/BlockContent/BlockContent";
import { navigateWithParamsForFiltering } from "../utils/navigate-with-params";

export const pageQuery = graphql`
  query BuyPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityBuyPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      _rawDescription(resolveReferences: { maxDepth: 5 })
      _rawChannelPartnersDescription(resolveReferences: { maxDepth: 5 })
      _rawOtherResellersDescription(resolveReferences: { maxDepth: 5 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    products: sanityProductListingPage(language: { eq: null }) {
      _id
      _type
      products {
        _id
        _type
        title
        subtitle
        slug {
          current
        }
      }
    }
    resellers: allSanityEcosystemMember(
      # Match everything. Glob or regex give duplicate results.
      filter: { products: { nin: "__" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        _id
        name
        resellerUrl
        resellerProducts {
          product {
            _id
            _type
            title
            slug {
              current
            }
          }
          productUrl
        }
        channelPartner
        countries {
          code
          name
        }
        logo {
          ...SanityImage
        }
      }
    }
  }
`;

interface WhereToBuyPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityPage & {
      _rawDescription: SanityRawContent;
      _rawChannelPartnersDescription: SanityRawContent;
      _rawOtherResellersDescription: SanityRawContent;
    };
    resellers: { nodes: SanityEcosystemMember[] };
    products: { products: SanityProduct[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

const WhereToBuyPage = ({
  pageContext,
  data: {
    menus,
    page,
    resellers: { nodes: unfilteredResellers },
    products: { products: unfilteredProducts }
  },
  location
}: WhereToBuyPageProps) => {
  const {
    title,
    _rawDescription: description,
    _rawChannelPartnersDescription: channelPartnersDescription,
    _rawOtherResellersDescription: otherResellersDescription
  } = page;
  const { resellers, navigateWithParams, options, query } = useBuyParams(
    unfilteredResellers,
    unfilteredProducts
  );
  const [channelPartners, otherResellers] = partition(
    resellers,
    x => !!x.channelPartner
  );

  const filterRows = (
    <Row>
      <Col offsetMd={1} md={3} sm={3} xs={12}>
        <BuyFilter
          value={query.product || null}
          placeholderId="product"
          placeholderFallback="Product"
          onChange={value => navigateWithParams({ product: value })}
          options={options.product as any}
          formatOptionLabel={({ label }: any) => (
            <Text as="span" variant="default">
              {label}
            </Text>
          )}
        />
      </Col>
      <Col md={3} sm={3} xs={12}>
        <BuyFilter
          value={query.location || null}
          placeholderId="location"
          placeholderFallback="Location"
          onChange={value => navigateWithParams({ location: value })}
          options={options.location as any}
          formatOptionLabel={({ label }: any) => (
            <Text as="span" variant="default">
              {label}
            </Text>
          )}
        />
      </Col>
    </Row>
  );

  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.BUY}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      location={location}
      strings={pageContext.strings}
    >
      <BuyPageLayout
        title={title}
        description={description}
        filterRows={filterRows}
        gradient="duskRed"
      >
        {channelPartners.length > 0 && (
          <>
            <Row className={styles.resellerRow}>
              <WithSpacing>
                <Col offsetMd={1} md={12}>
                  <Text variant="h2">
                    <GetText
                      id="channel-partners"
                      fallback="Channel partners"
                    />
                  </Text>
                </Col>
              </WithSpacing>
            </Row>
            {channelPartnersDescription && (
              <Row>
                <Col offsetMd={1}>
                  <BlockContent
                    bodyVariant="default"
                    content={channelPartnersDescription}
                  />
                </Col>
              </Row>
            )}
            <ResellerListing
              resellerType="channel-partner"
              resellers={channelPartners}
              cardTags={cardTags}
              productSlug={query.product}
            />
          </>
        )}
        {channelPartners.length > 0 && (
          <Row className={styles.resellerRow}>
            <WithSpacing>
              <Col offsetMd={1} md={12}>
                <Text variant="h2">
                  <GetText id="other-resellers" fallback="Other resellers" />
                </Text>
              </Col>
            </WithSpacing>
          </Row>
        )}
        {otherResellersDescription && (
          <Row className={channelPartners.length > 0 ? {} : styles.resellerRow}>
            <Col offsetMd={1}>
              <BlockContent
                bodyVariant="default"
                content={otherResellersDescription}
              />
            </Col>
          </Row>
        )}
        <ResellerListing
          resellerType="reseller"
          resellers={otherResellers}
          cardTags={cardTags}
          productSlug={query.product}
        />
      </BuyPageLayout>
    </PageLayout>
  );
};

const partition = function<T>(
  array: T[],
  predicate: (v: T) => boolean
): [T[], T[]] {
  const a: T[] = [];
  const b: T[] = [];
  array.forEach(item => {
    (predicate(item) ? a : b).push(item);
  });
  return [a, b];
};

const cardTags = ({ channelPartner, countries }: SanityEcosystemMember) => {
  const sortedCountries = sortBy(countries, c => c.name);
  const tags = [sortedCountries.map(c => <span key={c.code}>{c.name}</span>)];
  if (channelPartner) {
    tags.push([
      <span key="channel-partner">
        <GetText id="channel-partner" fallback="Channel partner" />
      </span>
    ]);
  }
  return tags;
};

interface Query {
  product?: string | undefined;
  location?: string | undefined;
}

const useBuyParams = (
  unfilteredResellers: SanityEcosystemMember[],
  unfilteredProducts: SanityProduct[]
) => {
  let location = useSearchParam("location");
  const allProducts = useString("all-products", "All products");
  const allLocations = useString("all-locations", "All locations");
  const locations: SanityCountry[] = sortBy(
    uniqBy(flatten(unfilteredResellers.map(r => r.countries)), c => c.code),
    c => c.name
  );
  if (!locations.find(x => x.code === location)) {
    location = null;
  }
  let product = useSearchParam("product");
  if (!unfilteredProducts.find(x => x.slug.current === product)) {
    product = null;
  }
  const query: Query = {
    product: product ?? undefined,
    location: location ?? undefined
  };
  const options = {
    product: [
      { value: undefined, label: allProducts },
      ...unfilteredProducts.map(p => ({
        value: p.slug.current,
        label: p.title
      }))
    ],
    location: [
      { value: undefined, label: allLocations },
      ...locations.map(c => ({ value: c.code, label: c.name }))
    ]
  };
  const navigateWithParams = (params: Partial<Query>) => {
    navigateWithParamsForFiltering({ ...query, ...params });
  };
  const filteredResellers = unfilteredResellers
    .filter(r => !location || r.countries.find(c => c.code === location))
    .filter(
      r =>
        !product ||
        !r.channelPartner ||
        r.resellerProducts.find(p => p.product.slug.current === product)
    );
  return {
    options,
    query,
    navigateWithParams,
    resellers: filteredResellers
  };
};

export default WhereToBuyPage;
